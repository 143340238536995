<template>
  <div id="Contact">
    <div class="baner"></div>
    <div class="box">
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">联系我们</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="flex bix-li-box">
          <div class="bix-li-box-img"></div>
          <div>
            <div class="bix-li-box-title hui333 fw5 fs24">杭州聚达物联科技有限公司</div>
            <div class="bix-li-box-title2 fs20 hui333 fw5">公司地址：</div>
            <div class="bix-li-box-title3 fs18 hui666">地址：杭州市上城区中豪五星国际4幢9楼</div>
            <div class="bix-li-box-title2 fs20 hui333 fw5">联系方式：</div>
            <div class="bix-li-box-title3 fs18 hui666">咨询热线：0571-56308881</div>
            <div class="bix-li-box-title3 fs18 hui666">邮箱：234829@126.com</div>
            <div class="bix-li-box-title3 fs18 hui666">网址：wwww.ljxd.com</div>
          </div>
        </div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
export default {
  name: "Contact",
      components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
};
</script>

<style scoped lang="less">
#Contact {
  .baner {
    width: 1920px;
    height: 576px;
    background-image: url(~@/assets/Contact1.png);
    background-size: 100% 100%;
  }
  .box {
    width: 1200px;
    margin: 0 360px;
    .bix-li {
      width: 1200px;
      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }
      .bix-li-box {
        width: 1200px;
        height: 558px;
        margin-top: 112px;
          margin-bottom: 120px;
        .bix-li-box-img {
          width: 783px;
          height: 558px;
          background-image: url(~@/assets/Contact2.png);
          background-size: 100% 100%;
          margin-right: 40px;
        }
        .bix-li-box-title {
            margin-top: 28px;
        }
         .bix-li-box-title2 {
            margin-top: 60px;
        }
         .bix-li-box-title3 {
            margin-top: 20px;
        }
      }
    }
  }
}
</style>